import React, { useState, useContext } from 'react';

const AlertStatus = {
    NONE: 'none',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
};

const AlertContext = React.createContext(null);
AlertContext.displayName = 'AlertContext';

export function AlertProvider({ children }) {
    const [alert, setAlert] = useState(AlertStatus.NONE);
    const [alertText, setAlertText] = useState(null);

    return (
        <AlertContext.Provider
            value={{
                alert: alert,
                alertText: alertText,
                success: (text, timeout) => {
                    setAlertText(text);
                    setAlert(AlertStatus.SUCCESS);
                    setTimeout(() => {
                        setAlert(AlertStatus.NONE);
                    }, timeout * 1000 || 10000);
                },
                error: (text, timeout) => {
                    setAlertText(text);
                    setAlert(AlertStatus.ERROR);
                    setTimeout(() => {
                        setAlert(AlertStatus.NONE);
                    }, timeout * 1000 || 10000);
                },
                clear: () => setAlert(AlertStatus.NONE),
                warning: (text, timeout) => {
                    setAlertText(text);
                    setAlert(AlertStatus.WARNING);
                    setTimeout(() => {
                        setAlert(AlertStatus.NONE);
                    }, timeout * 1000 || 10000);
                },
            }}
        >
            {children}
        </AlertContext.Provider>
    );
}

export function useAlertContext() {
    return useContext(AlertContext);
}
