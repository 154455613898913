import React, { useState, useContext } from 'react';

const EditContext = React.createContext(null);
EditContext.displayName = 'EditContext';

export function EditProvider({ children }) {
    const [display, setDisplay] = useState(false);

    return (
        <EditContext.Provider
            value={{
                display: display,
                show: () => {
                    setDisplay(true);
                },
                hide: () => {
                    setDisplay(false);
                },
            }}
        >
            {children}
        </EditContext.Provider>
    );
}

export function useEditContext() {
    return useContext(EditContext);
}
