import { useEffect } from 'react';
import Head from 'next/head';
import '../styles/global.css';
import { Provider, signIn, useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import { AppWrapper, useAppContext } from '../context/state';
import { AlertProvider } from '../context/alert';
import { ModalProvider } from '../context/modal';
import { EditProvider } from '../context/edit';
import { UserProvider } from '../context/user';

const accountSelectUrl = '/account/select';

export default function App({ Component, pageProps }) {
    return (
        <Provider session={pageProps.session}>
            <AppWrapper>
                <ModalProvider>
                    <AlertProvider>
                        <EditProvider>
                            <Head>
                                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css" integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA==" crossorigin="anonymous" />
                                <title>
                                    Blueotter camp | Gestão de documentos
                                </title>
                            </Head>
                            {Component.auth ? (
                                <Auth>
                                    <UserProvider user={pageProps.user}>
                                        <Component {...pageProps} />
                                    </UserProvider>
                                </Auth>
                            ) : (
                                <Component {...pageProps} />
                            )}
                        </EditProvider>
                    </AlertProvider>
                </ModalProvider>
            </AppWrapper>
        </Provider>
    );
}

function Auth({ children }) {
    const router = useRouter();
    const [session, loading] = useSession();
    const isUser = !!session?.user;
    const { store: context } = useAppContext();
    const { blueotter_store: store } = context;

    useEffect(() => {
        if (loading) return; // Do nothing while loading

        if (!isUser) signIn(); // If not authenticated, force log in

        if (!store || !store?.account) {
            return router.push(accountSelectUrl);
        }
    }, [loading, isUser, store]);

    if (isUser) {
        return children;
    }

    // Session is being fetched, or no user.
    // If no user, useEffect() will redirect.
    return <div>Redirecting...</div>;
}
