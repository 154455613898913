import React, { useState, useContext } from 'react';

const ModalContext = React.createContext(null);
ModalContext.displayName = 'ModalContext';

export function ModalProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({
        type: false,
        icon: false,
        title: false,
        description: false,
        action: {},
    });

    return (
        <ModalContext.Provider
            value={{
                isOpen: isOpen,
                data: data,
                setContent: (content) => setData(content),
                open: () => setIsOpen(true),
                close: () => setIsOpen(false),
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}

export function useModalContext() {
    return useContext(ModalContext);
}
