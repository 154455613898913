import React, { useState, useContext } from 'react';

const UserContext = React.createContext(null);
UserContext.displayName = 'UserContext';

export function UserProvider({ children, user: userProps }) {
    const [user, setUser] = useState(userProps);

    const hasPermission = (value) => {
        return !!user.permissions.find((item) => item === value);
    };

    return (
        <UserContext.Provider
            value={{
                user,
                hasPermission,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export function useUserContext() {
    return useContext(UserContext);
}
