import { createContext, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';

const AppContext = createContext();

export function AppWrapper({ children }) {
    const [store, setStore, removeStore] = useCookies(['blueotter_store']);
    //sidemenu
    const [isOpen, setIsOpen] = useState(true);

    return (
        <AppContext.Provider
            value={{ store, setStore, removeStore, isOpen, setIsOpen }}
        >
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}
